import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactDom from "react-dom";
import Context from "../../../context/Context";

// import css styles
import styles from "./PreviewVideo.module.css";

// icons

import { SlClose } from "react-icons/sl";
import useFetch from "../../../Hooks/UseFetch";

const PreviewVideo = ({
	openPreviewModal,
	setOpenPreviewModal,
	videoSrc,
	setVideoSrc,
}) => {
	// Fetch data from API
	// const { fetchedData } = useFetch(
	//   "https://api.msr7unaizah.com/api/dashboard/features/index"
	// );
	const [currentVideo, setCurrentVideo] = useState(null);

	console.log(openPreviewModal);

	// useEffect(() => {
	//   if (fetchedData) {
	//     setCurrentVideo(fetchedData?.data?.chinareson?.video);
	//   }
	// }, [fetchedData]);

	return (
		openPreviewModal && (
			<div onClick={() => setOpenPreviewModal(false)}>
				{/* Backdrop */}
				<div className={`${styles.backdrop} !z-[6000]`} />
				<div
					className={` !z-[7000] ${styles.z_index_100} absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-w-[600px]   w-[90%] h-[502px]  `}>
					<div className='w-full h-full  flex justify-start items-start gap-3'>
						{videoSrc ? (
							<video
								className='rounded-lg'
								autoPlay
								controls
								poster='https://placehold.co/400x250/png'
								src={videoSrc}
								type='video/mp4'
							/>
						) : (
							<video
								className='rounded-lg'
								autoPlay
								controls
								poster='https://placehold.co/400x250/png'
								src={currentVideo}
								type='video/mp4'
							/>
						)}

						<div
							className='cursor-pointer '
							onClick={() => setOpenPreviewModal(false)}>
							<SlClose
								className={styles.close_video_icon}
								title='اغلاق معاينة الفيديو'
							/>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

const PreviewVideoModal = ({
	openPreviewModal,
	setOpenPreviewModal,
	videoSrc,
	setVideoSrc,
}) => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<PreviewVideo
					{...{ openPreviewModal, setOpenPreviewModal, videoSrc, setVideoSrc }}
				/>,
				document.getElementById("preview-video-modal")
			)}
		</Fragment>
	);
};

export default PreviewVideoModal;
