import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third party
import { Helmet } from "react-helmet";

// import components
import { PagesBreadcrumbs } from "../../../../components";

// icons
import AlertDialog from "../../../../components/AlertBox/AlertBox";

// import css styes
import { DeleteForever, ModeEdit } from "@mui/icons-material";
import { IconButton, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllSectionsPagThunk } from "../../../../RTK/Thunk/AllSectionsPagThunk";
import { ChangeStatusThunk } from "../../../../RTK/Thunk/ChangeStatusThunk";
import { DeletePageSectionThunk } from "../../../../RTK/Thunk/DeletePageSectionThunk";
import AddNewsSectionModal from "../../../../components/AddNewsSectionModal/AddNewsSectionModal";
import EmptyBox from "../../../../components/EmptyBox/EmtyBox";
import PaginationBox from "../../../../components/PaginationBox/PaginationBox";
import SwitchBox from "../../../../components/SwitchBox/SwitchBox";
import AddNewBox from "../../../../components/addNewBox/AddNewBox";
import { Loader } from "../../../../components/loader";
import Context from "../../../../context/Context";
import "../../../../index.css";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#FDF1F3",
		color: "#000",
		fontSize: 18,
		fontWeight: 500,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 16,
		padding: "10px",
	},
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme?.palette?.action?.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const MainSectionsBox = () => {
	let dispatch = useDispatch();
	let params = useParams();
	// console.log(params)

	let { sectionData, lastPage, reload, currentPage } = useSelector(
		(state) => state.SectionsReducer
	);
	const [pageTarget, setPageTarget] = useState(1);
	const [showModalAddSection, setShowModalAddSection] = useState({
		show: false,
		type: "",
	});

	// handle open edit Article page
	const ArticleEditContext = useContext(Context);
	const { isArticleEdit, currentArticle, setActionTitle } = ArticleEditContext;
	useEffect(() => {
		if (params?.sectionId) {
			dispatch(
				AllSectionsPagThunk({ id: params?.sectionId, page: pageTarget })
			);
		}
	}, [params?.sectionId, pageTarget]);
	// =================delete-row======================
	const [deleteId, setDeleteId] = React.useState(0);
	const [openAlert, setOpenAlert] = React.useState(false);
	const handleDeleteSection = (id) => {
		dispatch(DeletePageSectionThunk({ id: params?.sectionId, section_id: id }))
			.unwrap()
			.then((data) => {
				dispatch(
					AllSectionsPagThunk({ id: params?.sectionId, page: pageTarget })
				);
				// toast.success("تم حذف العنصر بنجاح", {
				// 	theme: "light",
				// });
			})
			.catch((error) => {
				// handle error here
				// toast.error(error, {
				// 	theme: "light",
				// });
			});
	};

	return (
		<>
			<Helmet>
				<title>لوحة التحكم | انشاء قسم جديدة</title>
			</Helmet>

			<div className=' top-[70px] left-0 z-[19] w-full h-full bg-[#FFF] overflow-y-auto pb-20'>
				<div className='w-full  m-auto py-4  pr-[25px] pl-60'>
					{/* Pages Bread crumbs */}
					<section className='mb-10'>
						<PagesBreadcrumbs
							currentPage={
								isArticleEdit
									? `تعديل ${currentArticle?.title}`
									: "انشاء قسم  جديدة"
							}
							parentPage={"الصفحات الحالية"}
							isNested={true}
							route={"/create-page"}
						/>
					</section>

					{/* main blog title  */}

					<AddNewBox reload={reload} />
					{/* table */}
					{reload ? (
						<Loader />
					) : sectionData.length ? (
						<>
							<TableContainer component={Paper} className=' h-fit'>
								<Table sx={{ minWidth: 700 }} aria-label='customized table'>
									<TableHead>
										<TableRow>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الرقم
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												العنوان
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												النوع
											</StyledTableCell>
											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												تاريخ الانشاء
											</StyledTableCell>

											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												الحالة
											</StyledTableCell>

											<StyledTableCell
												align='center'
												className='!bg-primaryBg capitalize'>
												أجراءات
											</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{sectionData.map((row, index) => (
											<StyledTableRow key={row.id}>
												<StyledTableCell align='center'>
													{index + 1}
												</StyledTableCell>
												<StyledTableCell
													align='center '
													className='text-black !text-center'>
													<div className='truncate w-[300px] mx-auto'>
														{row.title}
													</div>
												</StyledTableCell>

												<StyledTableCell align='center'>
													{row.type}
												</StyledTableCell>
												<StyledTableCell align='center'>
													{row.created_at}
												</StyledTableCell>

												<StyledTableCell align='center'>
													<div
														data-name={row.status}
														onClick={(e) => {
															if (e.target.tagName == "INPUT") {
																if (e.currentTarget.dataset.name == "true") {
																	dispatch(
																		ChangeStatusThunk({
																			id: row.id,
																			type: "section",
																			active: false,
																		})
																	);
																	e.currentTarget.dataset.name = false;
																} else {
																	dispatch(
																		ChangeStatusThunk({
																			id: row.id,
																			type: "section",

																			active: true,
																		})
																	);
																	e.currentTarget.dataset.name = true;
																}
															}
														}}>
														<SwitchBox status={row.status} />
													</div>
												</StyledTableCell>

												<StyledTableCell align='center'>
													<div className='action flex items-center justify-center gap-2'>
														<IconButton
															aria-label=''
															onClick={() => {
																setShowModalAddSection({
																	show: true,
																	type: row?.type,
																	isEdit: true,
																	targetId: row.id,
																});
															}}>
															<ModeEdit />
														</IconButton>

														<IconButton
															aria-label=''
															onClick={() => {
																setOpenAlert(true);
																setDeleteId(row.id);
															}}>
															<DeleteForever className='text-[red]' />
														</IconButton>
													</div>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<PaginationBox
								current_page={currentPage}
								count={lastPage}
								setPageTarget={setPageTarget}
							/>
						</>
					) : (
						<EmptyBox />
					)}
					{/* Save and share buttons  */}
				</div>
			</div>
			<AlertDialog
				open={openAlert}
				setOpen={setOpenAlert}
				handleDelete={handleDeleteSection}
				deleteId={deleteId}
				setDeleteId={setDeleteId}
			/>
			<AddNewsSectionModal
				{...{ showModalAddSection, setShowModalAddSection }}
			/>
		</>
	);
};

export default MainSectionsBox;
