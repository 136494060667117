import React, { useContext, useEffect, useState } from "react";

// icon
import { CloseIcon, EditIcon, OKIcon, SaveIcon } from "../../assets/icons";

import axios from "axios";
import Context from "../../context/Context";
import styles from "./AddNewQuestion.module.css";

const AddNewQuestion = ({
	reload,
	setReload,
	showModalAddSection,
	setShowModalAddSection,
}) => {
	// this context to open and close add new question
	const AddNewQuestionModalContext = useContext(Context);
	const {
		isEdit,
		questions,
		setQuestions,
		questionTitle,
		setActionTitle,
		answerQuestion,
		setQuestionTitle,
		currentQuestions,
		commonQuestionsId,
		setAnswerQuestion,
		addNewQuestionToCommonQuestionsArray,
	} = AddNewQuestionModalContext;

	// onchange question title
	const handleQuestionTitle = (e) => {
		setQuestionTitle(e.target.value);
	};
	// onchange Answer Question
	const handleAnswerQuestion = (e) => {
		setAnswerQuestion(e.target.value);
	};
	// ----------------------------------------------------------

	// Handle Save question title and answers
	const [saveQuestionAlert, setSaveQuestionAlert] = useState(false);
	const handleSaveQuestion = () => {
		// Add new question to array
		const newId = questions?.length;
		setQuestions([
			...questions,
			{ id: newId, question: questionTitle, answer: answerQuestion },
		]);

		setSaveQuestionAlert(true);
		setQuestionTitle("");
		setAnswerQuestion("");
	};
	// -----------------------------------------------------------------

	// handle Add new Question to current questions array
	const handleAddNewQuestionToCurrentArray = () => {
		setQuestionTitle("");
		setAnswerQuestion("");

		const formData = new FormData();
		formData.append("question", questionTitle);
		formData.append("answer", answerQuestion);
		axios
			.post(
				`https://api.msr7unaizah.com/api/dashboard/common-questions/add-common-question/${commonQuestionsId}`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer  ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				if (res?.status === 200 && res?.statusText === "OK") {
					setReload(!reload);
					setActionTitle(res?.data?.message?.ar);
				} else {
					console.log(res?.data?.message?.ar);
				}
			});
	};
	//___________________________________________________________________

	// handle create edit current question
	const handleEditQuestion = () => {
		const formData = new FormData();
		formData.append("question", questionTitle);

		axios
			.post(
				`https://api.msr7unaizah.com/api/dashboard/common-questions/update-question/${currentQuestions?.id}`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer  ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				if (res?.status === 200 && res?.statusText === "OK") {
					setReload(!reload);
					setActionTitle(res?.data?.message?.ar);
				} else {
					console.log(res?.data?.message?.ar);
				}
			});
	};
	// -----------------------------------------------------------------

	// handle create edit current answer
	const handleEditAnswer = () => {
		const formData = new FormData();
		formData.append("answer", answerQuestion);

		axios
			.post(
				`https://api.msr7unaizah.com/api/dashboard/common-questions/update-answer/${currentQuestions?.id}`,
				formData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer  ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				if (res?.status === 200 && res?.statusText === "OK") {
					setReload(!reload);
					setActionTitle(res?.data?.message?.ar);
				} else {
					console.log(res?.data?.message?.ar);
				}
			});
	};
	// -----------------------------------------------------------------

	// To handle hidden save alert
	if (saveQuestionAlert === true) {
		setTimeout(() => {
			setSaveQuestionAlert(false);
		}, 2000);
	}

	// To set current questions if isEDIT
	useEffect(() => {
		if (currentQuestions && isEdit) {
			setQuestionTitle(currentQuestions?.question);
			setAnswerQuestion(currentQuestions?.answer);
		}
	}, [currentQuestions && isEdit]);

	return (
		<>
			<div className='  px-[30px]'>
				{/* Backdrop */}
				<div
					className={`${styles.backdrop} z-20`}
					onClick={() => setShowModalAddSection({ show: false, type: "" })}
				/>
				<div
					className={`z-50 absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-w-[750px]   w-[90%] h-[502px] bg-white rounded-[10px]`}>
					{/* title of modal */}
					<div
						className={`w-full h-11 ${
							isEdit ? "bg-[#30506Bff]" : "bg-unnamed_color_055345"
						}   flex justify-center items-center px-6 ${styles.modal_title}`}>
						<h4
							className={`text-[16px] ${
								isEdit ? "text-unnamed_color_055345" : "text-white"
							}  font-medium flex-1 text-center`}>
							{isEdit
								? `تعديل سؤال (${currentQuestions?.question})`
								: " اضافة سؤال جديد"}
						</h4>

						<div
							className='cursor-pointer '
							onClick={() => setShowModalAddSection({ show: false, type: "" })}>
							<CloseIcon
								className={`${
									isEdit ? "close-icon-black" : "close-icon-white"
								}`}
							/>
						</div>
					</div>

					{/* content of modal */}
					<div className='w-full h-full p-5 overflow-y-auto'>
						{/* Question Title */}
						<section className='mb-10'>
							<div className='w-full h-[56px] bg-[#F3F3F3] flex justify-start items-center gap-2 px-4 mb-4'>
								<EditIcon className={"edit-question"} />
								<input
									className='w-full h-full bg-transparent sssss text-[16px] text-unnamed_color_055345 font-normal'
									value={questionTitle}
									onChange={handleQuestionTitle}
									placeholder='صيغة السؤال'
								/>
							</div>
						</section>

						{isEdit && (
							<section className='mb-10'>
								<button
									disabled={!questionTitle}
									className='w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '
									onClick={handleEditQuestion}>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							</section>
						)}

						{/* Answer Question */}
						<section>
							<div className={`${isEdit ? "mb-4" : "mb-24"}`}>
								<div className='w-full h-[120px] bg-[#F3F3F3] flex justify-start items-start gap-2 p-4 '>
									<EditIcon className={"edit-question"} />

									<textarea
										className='resize-none w-full h-full bg-transparent text-[16px] text-unnamed_color_055345 font-normal '
										value={answerQuestion}
										onChange={handleAnswerQuestion}
										placeholder='إجابة السؤال'
									/>
								</div>
								{saveQuestionAlert && (
									<div className='h-full flex justify-start items-center gap-1'>
										<div className='w-5 h-5 rounded-full bg-[#30506Bff]'>
											<OKIcon className='ok-icon w-5 h-5' />
										</div>
										<h5 className=' text-[16px] font-normal text-[#3AE374]'>
											تم الحفظ
										</h5>
									</div>
								)}
							</div>

							{addNewQuestionToCommonQuestionsArray ? (
								<button
									disabled={!answerQuestion && questionTitle}
									className='w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '
									onClick={handleAddNewQuestionToCurrentArray}>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							) : (
								<button
									disabled={!answerQuestion && questionTitle}
									className='w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded '
									onClick={isEdit ? handleEditAnswer : handleSaveQuestion}>
									<span>
										<SaveIcon />
									</span>
									<span className='text-white text-lg font-medium '>حفظ</span>
								</button>
							)}
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddNewQuestion;
