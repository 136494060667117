import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, styled } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";

// import css styles
import "../../index.css";

// Icons
import { useEffect, useState } from "react";
import { CiSettings } from "react-icons/ci";
import { HiOutlineUser } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { LogoutThunk } from "../../RTK/Thunk/LogoutThunk";
import { ArrowDown, Logout } from "../../assets/icons";
import { UserImage } from "../../assets/images";

/** ---------- The Custom styles ----------- */
const AppBarsStyles = {
	"&.MuiPaper-root": {
		height: "70px",
		boxShadow: "none",
		backgroundColor: "#5D305Fff",
	},
};

const ToolbarStyles = {
	"&.MuiToolbar-root": {
		paddingRight: "90px",
		paddingLeft: "0px",
		minHeight: "70px",
	},
};

const muiItemStyles = {
	"&.MuiMenuItem-root": {
		height: "48px",
		fontFamily: "Tajawal",
		fontSize: "16px",
		fontWeight: 500,
		color: "#f6f6f6",

		"&:hover": {
			backgroundColor: "#846E85ff",
			color: "#fff",
		},
	},
};
/** ---------------------------------------- */

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.5s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

const TopBar = () => {
	let dispatch = useDispatch();

	const navigate = useNavigate();
	const userName = localStorage.getItem("userName");
	// const userImage = localStorage.getItem("avatar");
	let { myAccountData, reload } = useSelector(
		(state) => state.MyAccountReducer
	);
	const [userImage, setUserImage] = useState(localStorage.getItem("avatar"));
	useEffect(() => {
		if (localStorage.getItem("avatar")) {
			setUserImage(localStorage.getItem("avatar"));
		}
	}, [localStorage.getItem("avatar"), myAccountData]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// Create logout function
	const logout = () => {
		dispatch(LogoutThunk())
			.unwrap()
			.then((data) => {
				// console.log(data)
				// toast.success(data?.message, {
				// 			theme: "light",
				// 		});
				localStorage.clear();
				navigate("/login");
			})
			.catch((error) => {
				// console.log(error)
				// toast.error(error?.message, {
				// 	theme: "light",
				// });
				// handle error here
			});
		// axios
		// 	.post(
		// 		"https://api.msr7unaizah.com/auth/logout",

		// 		{
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `Bearer ${localStorage.getItem("token")}`,
		// 			},
		// 		}
		// 	)
		// 	.then((res) => {
		// 		if (res?.data?.code === 200 && res?.data?.type === "success") {
		// 			localStorage.clear();
		// 			navigate("/login");
		// 		} else {
		// 			toast.success(res?.data?.message, {
		// 				theme: "light",
		// 			});
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.error("Logout failed", error);
		// 		toast.error("An error occurred during logout", {
		// 			theme: "light",
		// 		});
		// 	});
	};

	// account setting menu
	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			sx={{
				"& .MuiPaper-root ": {
					width: "180px",
					backgroundColor: "#5D305Fff",
					top: "70px !important",
					boxShadow: "none",
				},
				"& .MuiList-padding": {
					paddingTop: "0",
					paddingBottom: "0",
				},
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}>
			<MenuItem
				className='group text-unnamed_color_C6C6C6'
				onClick={() => {
					handleMenuClose();
					navigate("/edit-my-account");
				}}
				sx={muiItemStyles}>
				<HiOutlineUser
					className={` w-6 h-6 group-hover:text-white text-unnamed_color_f6f6f6 ml-2`}
				/>
				حسابي
			</MenuItem>
			<MenuItem
				className='group text-unnamed_color_C6C6C6'
				onClick={() => {
					handleMenuClose();
					navigate("/setting-my-account");
				}}
				sx={muiItemStyles}>
				<CiSettings
					className={` w-6 h-6 group-hover:text-white text-unnamed_color_f6f6f6 ml-2`}
				/>
				الإعدادات
			</MenuItem>
		</Menu>
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='fixed' sx={AppBarsStyles}>
				<Box className='app_container'>
					<Toolbar sx={ToolbarStyles}>
						<Box sx={{ display: "flex", gap: "22px" }}>
							{/* user info */}
							<IconButton
								sx={{
									display: "flex",
									gap: "10px",

									"&.MuiIconButton-root": {
										"&:hover": { backgroundColor: "transparent" },
									},
								}}
								size='large'
								edge='end'
								aria-label='account of current user'
								aria-controls={menuId}
								aria-haspopup='true'
								onClick={handleProfileMenuOpen}
								color='inherit'>
								<StyledBadge
									overlap='circular'
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									variant='dot'>
									<Avatar
										alt='user name'
										src={userImage ? userImage : UserImage}
									/>
								</StyledBadge>

								<Box className='flex justify-center items-center gap-2 '>
									{userName ? (
										<p className='text-[16px] text-white font-light font-Tajawal'>
											{userName}
										</p>
									) : (
										<p className='text-[16px] text-white font-light font-Tajawal'>
											جمعية المسرح
										</p>
									)}

									<ArrowDown />
								</Box>
							</IconButton>
						</Box>

						<Box sx={{ flexGrow: 1 }} />

						{/* Logout icon */}
						<IconButton
							onClick={logout}
							size='large'
							edge='start'
							color='inherit'
							aria-label='open drawer'
							sx={{ mr: 2 }}>
							<Logout />
						</IconButton>
					</Toolbar>
				</Box>
			</AppBar>

			{renderMenu}
		</Box>
	);
};

export default TopBar;
