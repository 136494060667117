import React, { Fragment, useContext, useState } from "react";
import ReactDom from "react-dom";
import Context from "../../../../context/Context";

// icon
import { CloseIcon } from "../../../../assets/icons";

// css styles
import styles from "./CustomerServiceInfo.module.css";

const CustomerServiceInfo = () => {
	// this context to open and close customer service info modal
	const customerServiceModalContext = useContext(Context);
	const { setOpenCustomerServiceModal, customerServiceInfo } =
		customerServiceModalContext;

	// handle service Info Values
	const [customerServiceInfoValues, setCustomerServiceInfoValues] = useState({
		fullName: customerServiceInfo?.name,
		phoneNumber: customerServiceInfo?.phone,
		email: customerServiceInfo?.email,
		message: customerServiceInfo?.message,
	});

	// handle in change
	const handleOnChangeCustomerServiceInfo = (e) => {
		const { name, value } = e.target;
		setCustomerServiceInfoValues((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-20`} />
			<div
				className={` ${styles.z_index_100} absolute top-20 left-0 translate-x-[40%] md:w-[55%] w-full  bg-white rounded-[10px]`}>
				{/* title of modal */}
				<div
					className={`w-full h-11 bg-[#30506Bff]  flex justify-center items-center px-6 ${styles.modal_title}`}>
					<h4 className='text-[16px] text-[#200407] font-medium flex-1 text-center'>
						تفاصيل الرسالة
					</h4>

					<div
						className='cursor-pointer '
						onClick={() => setOpenCustomerServiceModal(false)}>
						<CloseIcon className='close-video-icon' />
					</div>
				</div>

				{/* content of modal */}
				<div className=' py-5 w-full overflow-auto'>
					{/* full Name */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							اسم العميل
						</h4>
						<input
							readOnly
							name='fullName'
							type='text'
							value={customerServiceInfoValues?.fullName}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='أحمد عبد العزيز المالكي'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* phoneNumber */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							رقم الجوال
						</h4>
						<input
							readOnly
							name='phoneNumber'
							type='tel'
							value={customerServiceInfoValues?.phoneNumber}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='96651545461'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* Email */}
					<div className='border-b-[1px] border-solid border-[#e9e9e9] px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							البريد الالكتروني
						</h4>
						<input
							readOnly
							name='fullName'
							type='email'
							value={customerServiceInfoValues?.email}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='Ahmed@gmail.com'
							className='w-full h-7 border-none focus:border-none focus:outline-none font-normal text-[16px] text-black'
						/>
					</div>

					{/* message */}
					<div className='  px-5 mb-5'>
						<h4 className='mb-3 text-[#707070] font-normal text-[16px]'>
							محتوي الرسالة
						</h4>
						<textarea
							readOnly
							name='message'
							value={customerServiceInfoValues?.message}
							onChange={handleOnChangeCustomerServiceInfo}
							placeholder='أرغب بالاستفسار بشكل مفصل عن برنامج الرحلة التجارية إلى الصين'
							className='w-full h-56 p-5 bg-[#f2f2f2] resize-none font-normal text-[16px] text-black  '
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const CustomerServiceInfoModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<CustomerServiceInfo />,
				document.getElementById("customer-service-info")
			)}
		</Fragment>
	);
};

export default CustomerServiceInfoModal;
